import { HighlightedCode, InlineCode, MainCode } from "developers/_components/code";
import { FullWidthImage } from "developers/_components/images";
import { APISection } from "developers/_components/section";
import image_apikey from "assets/images/posts/api_key.png";
import React from "react";
import { capturePermission, registerSW, sw } from "./sw";

export const WebPushGuide = () => {
  return (
    <>
      <APISection title={<h2>Web Push Notification Setup</h2>}>
        <p>This guide will walk you through the process of setting up web push notifications with Go-Mailer.</p>
        <p>
          Web push notifications are small messages that you send from your applications or website to your user's
          browser. These messages will be displayed to your user even when they are not actively using the browser.
        </p>
      </APISection>
      <APISection title={<h3>Generate your API key</h3>}>
        <p>
          Before proceeding with the setup, you need to generate your API Key. The API key enables secure communications
          between your application and the Go-Mailer servers.
        </p>
        <p>
          On your Go-Mailer account, navigate to{" "}
          <b>
            <i>Settings</i>
          </b>
          {" > "}
          <b>
            <i>Developers</i>
          </b>
          . Click on the <InlineCode>Generate API key</InlineCode> button to generate your API key.
        </p>
        <FullWidthImage src={image_apikey} />
      </APISection>
      <APISection title={<h3>Download & Configure SDK in your app</h3>}>
        <p>
          Once your API Key is generated, you would need to configure the Go-Mailer Web Push SDK in your javascript web
          application. The SDK provides simple utility that enable you to capture notification permisions as well as
          store the permision credentials on our servers.
        </p>
        <h4>1. Install the package</h4>
        <MainCode>$ yarn add @go-mailer/web-push</MainCode>
        <h4>2. Create the service worker file</h4>
        <div>
          To receive and display web push notifications, you need to create a file named{" "}
          <InlineCode>gm-service-worker.js</InlineCode> in your root or public directory. Next, copy the following into
          that file.
        </div>
        <HighlightedCode code={sw} />
        <h4>3. Register the service worker</h4>
        <div>
          Finally, you will need to register the <InlineCode>gm-service-worker.js</InlineCode> file. To do call the{" "}
          <InlineCode>register()</InlineCode> function in the sdk. This function can be called anywhere within your app,
          however, we advise that it be called at the application's root or main file.
        </div>
        <HighlightedCode code={registerSW} />
      </APISection>
      <APISection title={<h3>Collect & Store the user's permision</h3>}>
        <p>
          As noted earlier, web push only works with contact profiles. Go-Mailer identifies your contacts or users by
          their email address, therefore you MUST send the contact's email address along with the permission data.
        </p>
        <p>
          We have provided a utility function that collects and stores the user's permission. All you need to do is pass
          in the user's email address and any extra user information.
        </p>
        <HighlightedCode code={capturePermission} />
        <p>
          <b>NOTE:</b> If the user does not exist in your Go-Mailer account, a new contact will be created.
        </p>
        <p>Congratulations, you have successfully integrated with Go-Mailer's web push notifications.</p>
      </APISection>
    </>
  );
};
