import config from "../.config";
import axios from "axios";

const base_url = `${config.users_uri}`;

export const contactUs = async (data) => {
  return axios.post(`${base_url}/guests/enquiry`, data);
};

export const unsubscribe = async (
  tracking_id,
  options = {},
  extra_details = {}
) => {
  return axios.put(
    `${base_url}/guests/unsubscribe/${tracking_id}?contact_email=${extra_details.email}&contact_id=${extra_details.id}`,
    {...options}
  );
};

export const create_record_from_webform = async (email, firstname = "") => {
  return axios.get(
    `${base_url}/api/contacts/${config.api_key}/capture/${config.audience_id}?email=${email}&firstname=${firstname}&source='website-api'`
  );
};

export const createContact = async (query) => {
  return axios.get(
    `${base_url}/api/contacts/${config.api_key}/capture/?&source=website-api&${query}`
  );
};
