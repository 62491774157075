import cx from "classnames";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./nav.module.css";
import nav_structure from "./structure.json";

const NavAccordion = ({ title = "Home", path = "#", kids = [], parent_path = "" }) => {
  const location = useLocation();
  const full_path = `${parent_path}${path}`;

  const [is_active, setIsActive] = useState(false);
  const [is_open, setIsOpen] = useState(false);

  const getFullPath = () => {
    const url = window.location.href;
    const [, url_part] = url.split("://");
    const parts = url_part.split("/");
    parts.shift();
    return `/${parts.join("/")}`;
  };

  useEffect(() => {
    const href = getFullPath();
    setIsActive(() => href === full_path);
    setIsOpen(() => href.includes(full_path));
  }, [full_path, location]);

  return (
    <>
      {kids.length > 0 ? (
        <details className={styles.accordionWrapper} name={parent_path} open={is_open}>
          <summary className={!!parent_path && styles.childAccordion}>
            {kids.length > 0 ? <>{title}</> : <NavLink to={full_path}>{title}</NavLink>}
          </summary>
          <div className={styles.childrenWrapper}>
            {kids.map((item) => (
              <NavAccordion key={item.path} {...item} parent_path={full_path} />
            ))}
          </div>
        </details>
      ) : (
        <div className={cx(styles.accordionLeafItem, is_active ? styles.active : "")}>
          {<NavLink to={full_path}>{title}</NavLink>}
        </div>
      )}
    </>
  );
};

export const DocumentationNav = () => {
  return (
    <div className={styles.wrapper}>
      {nav_structure.map((nav_item) => (
        <NavAccordion key={nav_item.title} {...nav_item} />
      ))}
    </div>
  );
};
