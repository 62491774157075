export const sample = `const response = await fetch("https://users.go-mailer.com/api/contacts", {
  method: "POST",
  headers: {
    Authorization: "Bearer <API_KEY>"
  },
  body: JSON.stringify({
    email: "johndoe@example.com",
    firstname: "John",
    lastname: "Doe",
    gender: "male",
    address: {
      country: "USA",
      state: "Oyo",
      street: "Sample street",
    }
  })
});`;
