import React from "react";
import { sample } from "./samples.js";
import { HighlightedCode, InlineCode, MainCode } from "../../_components/code";
import { BulletTitle } from "../../_components/misc/bullet";
import { APISection } from "../../_components/section";

export const AutomationAPIDoc = () => {
  return (
    <>
      <APISection id="introduction" title={<h2>Automation Event</h2>}>
        <p>
          This is an object which represents a custom automation event on Go-Mailer. These events can be created by you
          and they can be triggered from your applications.
        </p>
        <MainCode>{"VERB https://automata.go-mailer.com"}</MainCode>
      </APISection>
      <APISection id="trigger" title={<h3>Trigger an event</h3>}>
        <p>As stated in the introduction, you can trigger events from your own application.</p>
        <p>
          You generally need two key blocs of information: the code of the event to be triggered and a context object
          containing relevant information about the event.
        </p>
        <MainCode>POST https://automata.go-mailer.com/api/v1/events</MainCode>
      </APISection>
      <APISection id="request" title={<h3>Request body</h3>}>
        <div>
          <div>
            <BulletTitle>event_code - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>
            The is a unique code that is used to identify a specific Event. Two events SHOULD NOT have the same code.
          </p>
        </div>
        <div>
          <div>
            <BulletTitle>context - </BulletTitle>
            <InlineCode>object</InlineCode>
          </div>
          <p>
            This is a set of <InlineCode>{"Key => value"}</InlineCode> pairs which is expected to contain extra
            information related to the event and target for whom the event was triggered. This include the contact's
            information.
          </p>
        </div>
        <div>
          <div>
            <BulletTitle>contact_email - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>
            The user's email address. This is required and is used to identify the user for whom the event was
            triggered.
          </p>
        </div>
      </APISection>
      <APISection id="sample-request" title={<h3>Sample request</h3>}>
        <HighlightedCode code={sample} />
      </APISection>
    </>
  );
};
