import React, { useEffect, useRef } from "react";
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import "highlight.js/styles/default.css";
import styles from "./code.module.css";

hljs.registerLanguage("javascript", javascript);

export const InlineCode = ({ children }) => {
  if (typeof children !== "string") return null;
  return <span className={styles.inline}>{children}</span>;
};

export const MainCode = ({ children }) => {
  return <div className={styles.main}>{children}</div>;
};

export const HighlightedCode = ({ code }) => {
  const codeRef = useRef();
  useEffect(() => {
    if (codeRef && codeRef.current) {
      hljs.highlightBlock(codeRef.current);
    }
  }, [code]);

  return (
    <pre>
      <code className={`language-javascript`} ref={codeRef}>
        {code}
      </code>
    </pre>
  );
};
