import cx from "classnames";
import { Icon } from "assets/icons";
import React from "react";
import styles from "./feature.module.css";

export const Feature = ({
  icon,
  tagline = "Feature title",
  title = "Feature title",
  description = "Feature description",
  href,
  direction = "ltr",
  color,
  bgColor,
  cta,
}) => {
  return (
    <div className={cx(styles.feature, styles[direction])}>
      <div className={styles.featureContent}>
        <span className={styles.tag}>{tagline}</span>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
        <div className={styles.actionBtn}>
          <a href={href} className={styles.getStarted}>
            {cta}
          </a>
          <Icon name="arrow_right_flat" className={styles.icon} />
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img src={icon} className={styles.icon} alt={tagline} />
      </div>
    </div>
  );
};
