export const sample = `const response = await fetch("https://automata.go-mailer.com/api/v1/events", {
  method: "POST",
  headers: {
    Authorization: "Bearer <API_KEY>"
  },
  body: JSON.stringify({
    event_code: "makes_purchase",
    contact_email: "johndoe@example.com",
    context: {
      firstname: "John",
      lastname: "Doe",
      item: "iPhone",
      price: 1999.99
    }
  })
});`;
