export const sample = `const response = await fetch("https://mailing.go-mailer.com/api/v1/transactionals/dispatch", {
  method: "POST",
  headers: {
    Authorization: "Bearer <API_KEY>"
  },
  body: JSON.stringify({
    template_code: "BIRTHDAY_EMAIl",
    recipient_email: "johndoe@example.com",
    bcc: "admin@my-company.com",
    data: {
      firstname: "John",
      age: 47,
      ...
    },
    attachments: [{
      filename: "yourfile.pdf",
      path: "https://path/to/file",
      ...
    }]
  })
});`;
