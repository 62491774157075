import React from "react";
import { InlineCode, MainCode } from "../../_components/code";
import { BulletTitle } from "../../_components/misc/bullet";
import { APISection } from "../../_components/section";

export const APIDocIntroduction = () => {
  return (
    <>
      <APISection id="introduction" title={<h2>Introduction</h2>}>
        <p>
          The Go-Mailer API is structured around REST. Our APIs are designed to enable our users to manage important
          resources within their accounts.
        </p>
        <p>
          The APIs are designed to possess predictable URL structures and to support the most common HTTP verbs and
          error codes.
        </p>
        <MainCode>{"curl https://api.go-mailer.com/<endpoint-path>"}</MainCode>
      </APISection>
      <APISection id="authentication" title={<h3>Authentication</h3>}>
        <p>
          The Go-Mailer API uses API Keys to authenticate all requests. Your keys are accessible under Security settings
          in your account.
        </p>
        <p>
          Your API Key possesses elevated privileges within your account; please keep it safe and secure. Do not share
          your key in publicly accessible areas.
        </p>
        <p>HTTP requests are authenticated using Bearer Auth. your requests.</p>
        <MainCode>
          {"curl https://api.go-mailer.com/<endpoint-path>"} -H “Authorization: Bearer {"<API_KEY>"}”
        </MainCode>
      </APISection>
      <APISection id="responses" title={<h3>Response body</h3>}>
        <div>
          <div>
            <BulletTitle>error - </BulletTitle>
            <InlineCode>string | null</InlineCode>
          </div>
          <p>
            This is an optional message indicating any errors that occurred during the processing of the request. If no
            error occurs, this value is null
          </p>
        </div>
        <div>
          <div>
            <BulletTitle>payload - </BulletTitle>
            <InlineCode>object | null</InlineCode>
          </div>
          <p>
            This is an optional <InlineCode>{"key => value"}</InlineCode> pairs of information returned with successful
            responses. This property value is `null` when an error occurs whilst processing the request.
          </p>
        </div>
        <div>
          <div>
            <BulletTitle>payload.data - </BulletTitle>
            <InlineCode>Resource | Resource[]</InlineCode>
          </div>
          <p>This contains the actual Object or a list of Objects for which the request is made</p>
        </div>
        <div>
          <div>
            <BulletTitle>payload.meta.size - </BulletTitle>
            <InlineCode>number</InlineCode>
          </div>
          <p>This returns a count of the instances of the Resource that matches the query.</p>
        </div>
      </APISection>
      <APISection id="response-codes" title={<h3>Response codes</h3>}>
        <div>
          <InlineCode>200 - OK </InlineCode>
          <p>Everything worked as expected.</p>
        </div>
        <div>
          <InlineCode>400 - Bad Request </InlineCode>
          <p>The request was unacceptable, often due to missing a required parameter.</p>
        </div>
        <div>
          <InlineCode>403 - Unauthorized </InlineCode>
          <p>The request is does not have required authorization. Usually due to incorrect API Keys</p>
        </div>
        <div>
          <InlineCode>404 - Not Found </InlineCode>
          <p>The requested resource doesn't exist.</p>
        </div>
        <div>
          <InlineCode>500 - Server Errors </InlineCode>
          <p>Something went wrong on the Go-Mailer servers</p>
        </div>
      </APISection>
    </>
  );
};
