import React from "react";
import styles from "./input.module.css";
import cx from "classnames";

export const EmailInput = ({
  text = "Get Started",
  value = "",
  onInput = () => {},
  onSubmit = () => {},
  placeholder = "Enter work email",
}) => {
  return (
    <div className={styles.container}>
      <input
        value={value}
        placeholder={placeholder}
        onInput={(e) => onInput(e.target.value)}
        type="email"
        className={styles.input}
      />
      <button className={styles.button} onClick={onSubmit}>
        {text}
      </button>
    </div>
  );
};

export const SimpleInput = ({
  type = "text",
  label = "",
  name = "",
  input_id = "",
  className = "",
  wrapperClassName = "",
  disabled,
  placeholder = "",
  value = "",
  min = "",
  max = "",
  onBlur = () => {},
  onInput = () => {},
  onKeyDown = () => {},
  style = {},
  required = false,
  autoFocus = false,
  error = "",
}) => {
  return (
    <div className={cx(styles.formGroup, wrapperClassName)}>
      {label && (
        <label htmlFor={input_id}>
          {label}
          {required && <span className={styles.required}> *</span>}
        </label>
      )}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={() => {}}
        onKeyDown={onKeyDown}
        onInput={(e) => (name ? onInput(e.target) : onInput(e.target.value))}
        onBlur={onBlur}
        className={cx(className, {[styles.errorBorder]: error})}
        style={style}
        id={input_id}
        disabled={disabled}
        min={min}
        max={max}
        autoFocus={autoFocus}
      />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};
