import React from "react";
import styles from "./images.module.css";

export const FullWidthImage = ({ src }) => {
  return (
    <div className={styles.image}>
      <img src={src} alt="img" />
    </div>
  );
};
