export const sw = `const logAction = async (tracking_id) => {
  try {
    await fetch(\`https://messaging.go-mailer.com/webhooks/push/web/\${tracking_id}\`);
  } catch (e) {
    console.log("Unable to log action");
    return null;
  }
};

const tracker = { tracking_id: "" };

self.addEventListener("push", (event) => {
  if (!event.data) return;
  const { title, body, icon, tracking_id } = event.data.json();

  tracker.tracking_id = tracking_id;
  const promiseChain = self.registration.showNotification(title, { body, icon });
  event.waitUntil(promiseChain);
});

self.addEventListener("notificationclick", (event) => {
  const clickedNotification = event.notification;
  clickedNotification.close();

  const promiseChain = logAction(tracker.tracking_id);
  event.waitUntil(promiseChain);
});`;

export const registerSW = `import GoMailerWebPush from "@go-mailer/web-push";
GoMailerWebPush.register(<api_key>);
`;

export const capturePermission = `import GoMailerWebPush from "@go-mailer/web-push";
// your code

GoMailerWebPush.requestPermission().then(subscription_token => {
  if (subscription_token) {
    GoMailerWebPush.saveSubscriptionToken(subscription_token, { email });
  }
});

// you code
`;
