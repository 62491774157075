import React from "react";
import { Route, Routes } from "react-router-dom";
import styles from "./docs.module.css";
import { AutomationAPIDoc } from "./api/automation";
import { ContactAPIDoc } from "./api/contacts";
import { APIDocIntroduction } from "./api/introduction";
import { TransactionalAPIDoc } from "./api/transactional";
import Header from "./header/index.js";
import { DocumentationNav } from "./nav";
import { WebPushGuide } from "./guides/webpush";

export default function Documentation() {
  return (
    <div className={styles.wrapper}>
      <Header />
      <div className={styles.layout}>
        <div className={styles.navWrapper}>
          <DocumentationNav />
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <Routes>
              <Route path="guides/">
                <Route path="webpush/*" element={<WebPushGuide />} />
              </Route>
              <Route path="reference/*">
                <Route path="automations/*" element={<AutomationAPIDoc />} />
                <Route path="contacts/*" element={<ContactAPIDoc />} />
                <Route path="get-started/*" element={<APIDocIntroduction />} />
                <Route path="transactionals/*" element={<TransactionalAPIDoc />} />
              </Route>
              <Route path="/*" element={<APIDocIntroduction />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}
