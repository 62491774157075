import React from "react";
import styles from "./section.module.css";

export const APISection = ({ id, title, children }) => {
  return (
    <section id={id} className={styles.section}>
      <span className={styles.title}>{title}</span>
      <div className={styles.body}>{children}</div>
    </section>
  );
};
