import React from "react";
import cx from "classnames";
import config from ".config";
import styles from "./button.module.css";
import { Icon } from "assets/icons";

export const Button = ({ className = "", icon_name = "", text = "Button", onClick = () => {} }) => {
  return (
    <button className={cx(styles.button, className)} onClick={onClick}>
      {text}
      {icon_name && <Icon className={styles.icon} name={icon_name} />}
    </button>
  );
};

export const CTAButton = ({ text = "Get Started Today", className = "" }) => {
  const handleCTAClick = () => {
    window.location = `${config.app_url}/register`;
  };

  return <Button text={text} className={cx(styles.cta, className)} onClick={handleCTAClick} />;
};
