import React from "react";

import styles from "./reviews.module.css";
import generalStyle from "app.module.css";
import SwiperCore, { Navigation, Pagination, Mousewheel } from "swiper";
import "swiper/swiper.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { allReviews } from "./data";

SwiperCore.use([Navigation, Pagination, Mousewheel]);

export const Reviews = () => {
  return (
    <section className={styles.review}>
      <div className={generalStyle.gmContainer}>
        <div className={styles.revewContainer}>
          <h2>
            Amazing feedback from some amazing{" "}
            <span className={styles.blueText}>
              partners<span className={styles.stud}>.</span>
            </span>
          </h2>
          <div className={styles.revewList}>
            {allReviews.map((review) => (
              <div key={review.name} className={styles.testimonyCard} style={{ borderColor: review.color }}>
                <p>{review.content}</p>
                <div className={styles.testimonyAuthor}>
                  <div className={styles.testimonyImage}>
                    <img
                      className={styles.authorIcon}
                      style={{ backgroundColor: review.color }}
                      src={review.image}
                      alt={review.company}
                    />
                  </div>
                  <div className={styles.authorInfo}>
                    <h4>{review.name}</h4>
                    <h5>{review.company}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
