import React from "react";
import { sample } from "./samples.js";
import { HighlightedCode, InlineCode, MainCode } from "../../_components/code";
import { BulletTitle } from "../../_components/misc/bullet";
import { APISection } from "../../_components/section";

export const TransactionalAPIDoc = () => {
  return (
    <>
      <APISection title={<h2>Transactional Emails</h2>}>
        <p>
          The Transactional Email Object represents a transactional email template in your Go-Mailer account. The API
          allows you to send or dispatch transactionals to your contacts.
        </p>
      </APISection>
      <APISection title={<h3>Send an email</h3>}>
        <p>
          Sends the specified transactional email to the specified recipients. A recipient does not have to be a contact
          in your account.
        </p>
        <MainCode>PUT https://mailing.go-mailer.com/api/v1/transactionals/dispatch</MainCode>
      </APISection>
      <APISection title={<h3>Request body</h3>}>
        <div>
          <div>
            <BulletTitle>template_code - </BulletTitle>
            <InlineCode>string</InlineCode>
            {" - "}
            <InlineCode>required</InlineCode>
          </div>
          <p>The transactional template's unique code.</p>
        </div>
        <div>
          <div>
            <BulletTitle>recipient_email - </BulletTitle>
            <InlineCode>string</InlineCode>
            {" - "}
            <InlineCode>required</InlineCode>
          </div>
          <p>The email address of the recipient.</p>
        </div>
        <div>
          <div>
            <BulletTitle>bcc - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>The email address to blind copy.</p>
        </div>
        <div>
          <div>
            <BulletTitle>html - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>
            A valid HTML document, if the event where you would like to use your own predesigned or pregenerated HTML
            markup as the email content. This will override the design your created in your Go-Mailer account.
          </p>
        </div>
        <div>
          <div>
            <BulletTitle>data - </BulletTitle>
            <InlineCode>object</InlineCode>
          </div>
          <p>
            A <InlineCode>{"key => value"}</InlineCode> set of information which will be used to replaced any
            placeholders specified in the transactional email.
          </p>
          <p>
            The key should be the name of the placeholder used. e.g: if you used <InlineCode>@firstname</InlineCode>{" "}
            placeholder in your template, then your data object should look like this{" "}
            <InlineCode>{'{ firstname: "Sample Name" }'}</InlineCode>
          </p>
        </div>
        <div>
          <div>
            <BulletTitle>attachments - </BulletTitle>
            <InlineCode>object[]</InlineCode>
          </div>
          <p>
            This is a list of attachments to be sent via the transactional. Each attachment MUST NOT be more than 1MB.
          </p>
        </div>
        <div>
          <div>
            <BulletTitle>attachment.filename - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>This is the name of the attachment as it should be displayed to the recipient.</p>
        </div>
        <div>
          <div>
            <BulletTitle>attachment.path - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>The link to the file if the attachment is hosted on a remote server.</p>
        </div>
        <div>
          <div>
            <BulletTitle>attachment.content - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>
            This is a base64 representation of the attachment. This is best where the attachment is not hosted remotely.
          </p>
        </div>
        <div>
          <div>
            <BulletTitle>attachment.contentType - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>
            This is the mimetype of the attachment. The supported values include:{" "}
            <InlineCode>application/pdf.</InlineCode>
          </p>
        </div>
        <div>
          <div>
            <BulletTitle>attachment.encoding - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>
            This is the attachment's content encoding. This should be <InlineCode>base64.</InlineCode>
          </p>
        </div>
      </APISection>
      <APISection id="sample-request" title={<h3>Sample request</h3>}>
        <HighlightedCode code={sample} />
      </APISection>
    </>
  );
};
