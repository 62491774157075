import React from "react";
import { sample } from "./samples";
import { HighlightedCode, InlineCode, MainCode } from "../../_components/code";
import { BulletTitle } from "../../_components/misc/bullet";
import { APISection } from "../../_components/section";

export const ContactAPIDoc = () => {
  return (
    <>
      <APISection title={<h2>Contacts</h2>}>
        <p>
          The Contact object represents an individual contact in your Go-Mailer account. Go-Mailer provides you with
          programmatic access to create and modify the contacts in your account.
        </p>
      </APISection>
      <APISection title={<h2>Create contact</h2>}>
        <p>
          This allows you to create and modify a contact in your Go-Mailer account. A new contact if the email address
          is not in use. Otherwise, the existing contact is updated.
        </p>
        <MainCode>POST https://users.go-mailer.com/api/contacts</MainCode>
      </APISection>
      <APISection title={<h3>Request body</h3>}>
        <div>
          <div>
            <BulletTitle>email - </BulletTitle>
            <InlineCode>string</InlineCode>
            {" - "}
            <InlineCode>required</InlineCode>
          </div>
          <p>The contact's email address.</p>
        </div>
        <div>
          <div>
            <BulletTitle>firstname - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>The contact's first name.</p>
        </div>
        <div>
          <div>
            <BulletTitle>lastname - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>The contact's last name.</p>
        </div>
        <div>
          <div>
            <BulletTitle>gender - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>
            The contact's gender. Allowed values are <InlineCode>male</InlineCode> | <InlineCode>female</InlineCode>
          </p>
        </div>
        <div>
          <div>
            <BulletTitle>date_of_birth - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>The contact's date of birth. The following format should be used mm-dd-YYYY.</p>
        </div>
        <div>
          <div>
            <BulletTitle>phone_number - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>
            The contact's mobile number. If you wish to use SMS Marketing, please ensure to include the short code. e.g:
            <InlineCode>+234 123 456 7890</InlineCode>
          </p>
        </div>
        <div>
          <div>
            <BulletTitle>address - </BulletTitle>
            <InlineCode>object</InlineCode>
          </div>
          <p>The contact's home address.</p>
        </div>
        <div>
          <div>
            <BulletTitle>address.street - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>The street component of the contact's address.</p>
        </div>
        <div>
          <div>
            <BulletTitle>address.state - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>The state component of the contact's address.</p>
        </div>
        <div>
          <div>
            <BulletTitle>address.country - </BulletTitle>
            <InlineCode>string</InlineCode>
          </div>
          <p>
            The country code component of the contact's address. e.g <InlineCode>NG</InlineCode>
          </p>
        </div>
        <div>
          <div>
            <BulletTitle>extra_data - </BulletTitle>
            <InlineCode>object</InlineCode>
          </div>
          <p>
            A <InlineCode>{"key => value"}</InlineCode> set of custom information you would like to store on the
            contact. Useful for attribution.
          </p>
        </div>
      </APISection>
      <APISection id="sample-request" title={<h3>Sample request</h3>}>
        <HighlightedCode code={sample} />
      </APISection>
    </>
  );
};
